import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  useColorModeValue,
  useToast,
  Text,
  Image,
  HStack,
  Spinner,
  InputGroup,
  InputLeftAddon,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Select,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  VStack,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tooltip,
} from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
//   import c2 from '../../images/c2.png' 
//   import netone from '../../images/netone.png'
//   import netlogo from '../../images/netlogo.png'
//   import netmoney from '../../images/netmoney.jpg'
import bg5 from '../images/airImage.png';
import axios from '../../api/axios';
import Navbar from '../pages/Navbar';
import { FaCheckCircle, FaHome } from "react-icons/fa";
import { MdOutlineHome } from "react-icons/md"
import oneMoney from '../images/c2.png'
import zesa from '../images/zesa.jpg';


// shop.onemoney.co.zw/api

// const BUYZESA_URL = 'zesa/v1/buy-token/online'
// const GET_DETAILS_URL = 'zesa/v1/customer'

const BUYZESA_URL = 'https://shop.onemoney.co.zw/api/zesa/v1/buy-token/online'
const GET_DETAILS_URL = 'https://shop.onemoney.co.zw/api/zesa/v1/customer'


function BuyZESA() {


  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()

  const { isOpen: isConOpen, onOpen: onConOpen, onClose: onConClose } = useDisclosure()



  const [loading, setLoading] = useState(false)
  const [chechPhone, setCheckPhone] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [nameError, setNameError] = useState('')
  const [currError, setCurrError] = useState('')

  const [buyerError, setBuyerError] = useState("")
  const [buyerPhoneNumber, setBuyerPhoneNumber] = useState("")
  const [meter, setMeter] = useState('')
  const [amount, setAmount] = useState('')
  const [currency, setCurrency] = useState('')

  const [Details, setDetails] = useState(null)

  const [meterDetails, setMeterDetails] = useState(null)
  const [receiptData, setReceiptData] = useState(null)


  const [amounterror, setAmounterror] = useState("")
  const navigate = useNavigate();
  const [selectOption, setSelectOption] = useState('')
  const [showSelf, setShowSelf] = useState(false)
  const [showOthers, setShowOthers] = useState(false);



  const toast = useToast();


  useEffect(() => {
    if (selectOption === 'self') {
      setShowSelf(true)
    } else {
      setShowSelf(false)
    }
  }, [selectOption])

  useEffect(() => {
    if (selectOption === 'other') {
      setShowOthers(true)
    } else {
      setShowOthers(false)
    }
  }, [selectOption])

  const getDetails = async () => {
    try {
     
      setLoading(true)
      const response = await axios.get(`${GET_DETAILS_URL}/${meter}`, {

      })
      if (response.data.success === true) {
        setMeterDetails(response.data)
        setLoading(false)
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // toast({
        //   title: "Error",
        //   description: `Server responded with ${error.message}`,
        //   status: "error",
        //   duration: 5000,
        //   isClosable: true,
        // });
      } else {
        // Something happened in setting up the request that triggered an Error


      }
    }
  }

  useEffect(() => {
    getDetails();
    setTimeout(() => {

    }, 6000);

  }, [])



  const handleSubmit = async () => {
    setErrorMessage('')
    const res = validate();
    if (!res) {
      toast({
        title: 'Failed',
        description: "Form not complete",
        status: 'error',
        duration: 3000,

      });
      return;
    }
    //  setLoading(true)
    setCheckPhone(true)
    // toast({
    //   title: "Loading...",
    //   description: "Please check your phone to enter pin",
    //   status: "info",
    //   position:'top',
    //   variant: 'left-accent',
    //   duration: 9000,
    //   isClosable: true,
    // });
    // const token = localStorage.setItem("eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJyY2hpa3VtYnVAbmV0b25lLmNvLnp3IiwianRpIjoiNjQyNTMzYzIyNGJmNmI5NjBhN2QwOTQ0IiwidXNlclR5cGUiOiJFTVBMT1lFRSIsInJvbGUiOiJTVVBFUl9BRE1JTiIsInRpdGxlIjoiU1VQRVIgQURNSU4iLCJpYXQiOjE2OTA4OTE3MjIsImV4cCI6MTY5MDk3ODEyMn0.BjSRVVzb1ecR5_20AVw2LTieOlngfvaYOoL29W96naEaeBVPETkjpNstBqJL41CSbIh-oePXq4WmvdS34_EO0g", token);
    await axios.post(BUYZESA_URL, {
      buyerPhoneNumber: +263 + buyerPhoneNumber,
      amount,
      currency,
      meterNumber: meter,
      channel: 'WEB',
      customerName: meterDetails.data.name,
      customerPhoneNumber: meterDetails.data.phoneNumber,
      meterCurrency: meterDetails.data.meterCurrency

    }, {
      headers: {
        "Content-type": "application/json",
      }

    })
      .then(response => {
        if (response.data.success) {

          onClose();
          setReceiptData(response.data)
          onConOpen()
          toast({
            title: 'Zesa Purchase',
            description: response.data.message,
            status: 'success',
            position: 'top',
            variant: 'left-accent',
            duration: 9000,

          });

          setCheckPhone(false)
        }

        else {

          setCheckPhone(false)
          toast({
            title: 'token Failed',
            description: response.data.message,
            status: 'error',
            position: 'top',
            variant: 'left-accent',
            duration: 3000,

          });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response)
          toast({
            title: "Zesa Purchase Failed",
            description: `${error.response.data.message}`,
            status: "error",
            position: "bottom",
            variant: "left-accent",
            duration: 5000,
            isClosable: true,
          });
          //  setLoading(false)
          setCheckPhone(false)
        }else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Zesa Purchase Failed",
            description: "Token purchase failed, you can try again later",
            status: "error",
            position: 'bottom',
            variant: 'left-accent',
            duration: 8000,
            isClosable: true,
          });
          //  setLoading(false)
          setCheckPhone(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.response.data.message}`,
            status: "error",
            position: 'bottom',
            variant: 'left-accent',
            duration: 8000,
            isClosable: true,
          });

        }
      })
  }


  const validate = () => {
    const PHONE_REGEX = /^(71)/;

    if (!meter) {
      setNameError('Enter meter number')
      return false
    }

    if (!currency) {
      setCurrError('Please select currency')
      return false

    }
    

    if (!amount) {
      setAmounterror('Enter amount')
      return false
    }

    if (!buyerPhoneNumber) {
      setBuyerError('Enter Number')
      return false;
    }

    if (buyerPhoneNumber.length < 9) {
      setBuyerError('phone number invalid');
      return false;
    }
    if (buyerPhoneNumber.length > 10) {
      setBuyerError('phone number invalid');
      return false;
    }

    if (!PHONE_REGEX.test(buyerPhoneNumber)) {
      setBuyerError('please enter a netone number');
      return false;
    }



    return true;
  }


  return (
    <>
      <Navbar />
      <Breadcrumb m={6} fontWeight='medium' fontSize='sm'>
        <BreadcrumbItem>
          <BreadcrumbLink href='/'>Home</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='/admin/user-management.app'>Zesa Electricity Tokens</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box>

   
      <Stack marginLeft={{ base: '0', sm: '4', md: '8'}} minH={'40vh'} direction={{ base: 'column', md: 'row' }}>
        <Flex  flex={1}>

          
          <Box
            // boxSize={'450px'}
            // height={'440'}
            // ml={12}
            // mt={4}
            // rounded={'lg'}
            // bg={useColorModeValue('white', 'gray.700')}
            // boxShadow={'lg'}
            // p={8}
            maxW={{ base: "100%", sm: "90%", md: "120%" }}
            display='flex'
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
              borderWidth="1px"
                          rounded="lg"
                          shadow="lg"
                          ml={2}
                          p={6}
            
            >

            <Stack align={'center'} m={4} >
              <Heading fontSize={'25px'}
                lineHeight={1.1}
                fontWeight={400}
                marginTop='-4'
                marginBottom='2'
                textColor={'#FF7300'}
              > ZESA Pre-Paid Electricity
              </Heading>
            </Stack>

            <FormControl>
              <FormLabel>
                Enter Meter Number
              </FormLabel>
              <Input
                type="number"
                name='meter'
                value={meter}
                placeholder='enter 11 digit number'
                w='290px'
                onChange={(e) => setMeter(e.target.value)}
                onFocus={() => { setNameError('') }} borderColor={nameError ? 'red.400' : 'gray.300'}

              />
              <Text color={'red'}>{nameError} </Text>

            </FormControl>

            <FormControl mt={2} id="amount">
              <FormLabel>Amount:</FormLabel>
              <InputGroup>
                <Select w='160px' name='currency'
                  onChange={(e) => setCurrency(e.target.value)}
                  onFocus={() => { setCurrError('') }} borderColor={currError ? 'red.400' : 'gray.300'}
                >
                  <option value=''>
                    <InputLeftAddon children='select currency' />
                  </option>
                  <option value='USD'>
                    <InputLeftAddon children='USD' />
                  </option>
                  <option value='ZWG'>
                    <InputLeftAddon children='ZWG' />
                  </option>
                </Select>
                <Input
                  type="number"
                  placeholder='enter amount'
                  w="130px"
                  onChange={(e) => setAmount(e.target.value)}
                  onFocus={() => { setAmounterror('') }} borderColor={amounterror ? 'red.400' : 'gray.300'}

                />

              </InputGroup>
              <Text color={'red'}>{amounterror}</Text>
              <Text color={'red'}>{currError}</Text>
            </FormControl>


            <FormControl id="buyer">
              <FormLabel>Your Mobile Number:</FormLabel>
              <InputGroup>
                <InputLeftAddon children='+263' />
                <Input
                  type="text"
                  placeholder='eg.7100000000'
                  w='220px'
                  onChange={(e) => setBuyerPhoneNumber(e.target.value)}
                  onFocus={() => { setBuyerError('') }} borderColor={buyerError ? 'red.400' : 'gray.300'}

                />
              </InputGroup>
              <Text color={'red'}>{buyerError}</Text>
            </FormControl>

            {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            }

            <Stack align={'center'} m={4}>
              <Button
                //  isLoading={loading} 
                loadingText='Loading'
                colorScheme='teal'
                variant='outline'
                spinnerPlacement='end'
                w={'250px'}
                onClick={() => {
                  getDetails()
                  onOpen()

                }}
                bg={'#FF7300'}
                color={'white'}
                _hover={{
                  bg: 'orange.500',
                }}>
                Buy Now
              </Button>
            </Stack>
          </Box>
        </Flex>
        <Flex flex={1} justify="center" align="center">
          <Box>
            <Image
              objectFit={'contain'}
              height={{ base: '300px', md: '450px' }}
              src={zesa}
            />
          </Box>
        </Flex>
      </Stack>
      </Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        closeOnOverlayClick={false}
        isCentered
      
      >
        <AlertDialogOverlay>
          <AlertDialogContent
          
          >
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Online Payment
            </AlertDialogHeader>
            {loading ? (
              <Flex align='center' m={4} justify={'center'} direction={'column'}>
                <Spinner
                  thickness='4px'
                  speed='0.85s'
                  emptyColor='gray.200'
                  color='orange.300'
                  size='xl'
                />
                <Text mt={2} color={'gray.800'} fontWeight={'semibold'}>...processing customer details</Text>

              </Flex>
            ) : (
              <AlertDialogBody>

                <Box
                  bg={'gray.50'}
                  rounded='lg'
                  boxShadow={'sm'}
                >


                  <Stack
                    textAlign={'center'}
                    p={6}
                    // color={useColorModeValue('gray.800', 'white')}
                    color={'gray.800'}
                    align={'center'}>
                    <Text
                      fontSize={'sm'}
                      fontWeight={500}
                      // bg={useColorModeValue('green.50', 'green.900')}
                      bg={'orange.200'}
                      p={2}
                      px={3}
                      color={'white'}
                      rounded={'full'}>
                      Amount
                    </Text>
                    <Stack direction={'row'} align={'center'} justify={'center'}>
                      <Text fontSize={'3xl'}>{currency}</Text>
                      <Text fontSize={'6xl'} fontWeight={800}>
                        {amount}
                      </Text>
                    </Stack>
                  </Stack>
                </Box>
                <Text mt={3} fontWeight='semi-bold'>
                  Please confirm the following customer details below:
                </Text>
                <VStack align='left' m={1} spacing={4}>
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                      Customer Name:
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      {meterDetails?.data.name}
                    </Text>
                  </Box>
                  <Divider orientation='horizontal' />
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                      Residential Address:
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      {meterDetails?.data.addressLine1}
                    </Text>
                    <Text fontSize="lg" color={'gray.500'}>
                      {meterDetails?.data.addressLine2}
                    </Text>
                    <Text fontSize="lg" color={'gray.500'}>
                      {meterDetails?.data.addressLine3}
                    </Text>
                    <Text fontSize="lg" color={'gray.500'}>
                      {meterDetails?.data.addressLine4 === null ? meterDetails?.data.addressLine4 : ''}
                    </Text>
                  </Box>
                  <Divider orientation='horizontal' />
                </VStack>
                <Stack align={'center'} m={4}>

                  <Button
                    isLoading={chechPhone}
                    loadingText='Loading'
                    variant='solid'
                    spinnerPlacement='end'
                    w={'250px'}
                    onClick={() => {
                      handleSubmit()

                      // setLoading(true)
                      //   toast({
                      //     title: '...Loading',
                      //     description: 'Please check your phone and enter pin',
                      //     status:'loading',
                      //     position: 'top',
                      //     variant: 'left-accent',
                      //     isClosable: true
                      //   })

                    }

                    }
                    bg={'#FF7300'}
                    color={'white'}
                    _hover={{
                      bg: 'black',
                      color: 'white'
                    }}>
                    Proceed
                  </Button>
                </Stack>



              </AlertDialogBody>
            )}

            {chechPhone ? (
              <Flex align='center' m={4} justify={'center'} direction={'column'}>
                <Spinner
                  thickness='4px'
                  speed='0.85s'
                  emptyColor='gray.200'
                  color='orange.300'
                  size='xl'
                />
                <Text mt={2} color={'gray.800'} fontWeight={'semibold'}>...processing, please check your phone to enter pin</Text>
              </Flex>
            ) : (
              <>
                {/* <Text>Try Again later</Text> */}
              </>
            )}
            <AlertDialogFooter>
              <Button ref={cancelRef} bgColor={'red'} color='white' onClick={onClose}>
                Cancel
              </Button>
              {/* <Button colorScheme='green' onClick={handlesubmit} ml={3}>
        Proceed
      </Button> */}
            </AlertDialogFooter>


          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isConOpen}
        onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bgColor={'blackAlpha.800'} color={'white'}>Zesa Token result</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack mt={2} spacing={1} mb={3}>

              <HStack align={'left'} >

                <Heading as="h2" fontSize="md" color={'#FF7300'}>
                  Receipt Number:
                </Heading>

                <Text fontSize="md" color={'gray.500'}>
                  {/* {meterDetails?.data.name} */}
                  {receiptData?.data.receiptNumber}
                </Text>
              </HStack>
              <HStack align={'left'} >

                <Heading as="h2" fontSize="md" color={'#FF7300'}>
                  Meter number:
                </Heading>

                <Text fontSize="md" color={'gray.500'}>
                  {/* {meterDetails?.data.name} */}
                  {receiptData?.data.meterNumber}
                </Text>
              </HStack>
              <HStack align={'left'} >

                <Heading as="h4" fontSize="md" color={'#FF7300'}>
                  Customer Name:
                </Heading>

                <Text fontSize="md" color={'gray.500'}>
                  {meterDetails?.data.name}

                </Text>
              </HStack>
              <HStack align={'left'} >

                <Heading as="h2" fontSize="md" color={'#FF7300'}>
                  Date:
                </Heading>

                <Text fontSize="md" color={'gray.500'}>
                  {/* {meterDetails?.data.name} */}
                  {receiptData?.data.transactionDate}
                </Text>
              </HStack>

            </Stack>
            <Divider my={2} />
            <Heading as="h1" size="md" textAlign="center" mb={1}>
              Electricity Token
            </Heading>
            {/* <Text textAlign="center" mb={2}>1975 unit A</Text>
    <Text textAlign="center" mb={4}>12/23/20</Text> */}
            <Heading textAlign={'center'} as="h4" fontSize="sm" color={'#FF7300'}>

              {meterDetails?.data.addressLine1}


              {meterDetails?.data.addressLine2}


              {meterDetails?.data.addressLine3}


              {meterDetails?.data.addressLine4 === null ? meterDetails?.data.addressLine4 : ''}

            </Heading>
            <Stack spacing={1}>
              <Text textAlign={'center'} fontSize="lg" color={'gray.500'}>
                {receiptData?.data.token1 !== null ? receiptData?.data.token1 : ''}
              </Text>
              <Text textAlign={'center'} fontSize="lg" color={'gray.500'}>
                {receiptData?.data.token2 !== null ? receiptData?.data.token2 : ''}
              </Text>
              <Text textAlign={'center'} fontSize="lg" color={'gray.500'}>
                {receiptData?.data.token3 !== null ? receiptData?.data.token3 : ''}
              </Text>
            </Stack>
            <Divider mt={2} mb={4} />
            <VStack align='left' m={1} spacing={4}>
              <Box>
                <Heading as="h1" fontSize="md" color={'#FF7300'}>
                  Energy bought (kwh):
                </Heading>
                <Text fontSize="md" color={'gray.500'}>
                  {/* {meterDetails?.data.name} */}
                  {receiptData?.data.numberOfUnits}
                </Text>
              </Box>
              <Box>
                <Heading as="h1" fontSize="md" color={'#FF7300'}>
                  Units Cost
                </Heading>
                <Text fontSize="md" color={'gray.500'}>
                  {/* {meterDetails?.data.addressLine1} */}
                  {receiptData?.data.unitsCost}
                </Text>


              </Box>
              <Box>
                <Heading as="h1" fontSize="md" color={'#FF7300'}>
                  RE Levy:
                </Heading>
                <Text fontSize="md" color={'gray.500'}>
                  {receiptData?.data.reaLevy}
                </Text>
              </Box>
              <Box>
                <Heading as="h1" fontSize="md" color={'#FF7300'}>
                  Debt Collected:
                </Heading>
                <Text fontSize="md" color={'gray.500'}>
                  {receiptData?.data.debt}
                </Text>
              </Box>
              <Box>
                <Heading as="h1" fontSize="md" color={'#FF7300'}>
                  VAT
                </Heading>
                <Text fontSize="md" color={'gray.500'}>
                  {receiptData?.data.vat}
                </Text>
              </Box>
              <Box>
                <Heading as="h1" fontSize="md" color={'#FF7300'}>
                  Amount Tendered
                </Heading>
                <Text fontSize="md" color={'gray.500'}>
                  {receiptData?.data.totalAmount}
                </Text>
              </Box>
              {/* <Divider orientation='horizontal' /> */}

            </VStack>
            <Divider my={4} />
            <Flex justify="space-between">
              <Text fontWeight="bold" fontSize={'lg'}>Total Paid</Text>
              <Text fontWeight="bold" fontSize={'lg'}>{currency}{amount}</Text>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={onConClose}>
              Close
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>


    </>
  )
}

export default BuyZESA