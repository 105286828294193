import React from 'react'
import NavBar from '../pages/Navbar'
import {
  Button,
  Checkbox,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Image,
  InputGroup,
  ViewIcon,
  Box,
  Link,
  HStack,
  useColorModeValue,
  InputRightElement,
  InputLeftAddon,
  Select,
  useDisclosure,
  ModalBody,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalContent,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ModalCloseButton,
  ModalOverlay,
  useBreakpointValue,
  IconButton,
  AlertDialogBody,
  useToast,
  Divider,
  VStack,
  Spinner,

} from '@chakra-ui/react'
import { useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'


import { AddIcon, SearchIcon } from '@chakra-ui/react';
import { Radio, RadioGroup } from '@chakra-ui/react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import axios from '../../api/axios'
//   import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
// import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import one from '../images/banners/IMG-20241021-WA0008.jpg'
import two from '../images/banners/IMG-20241021-WA0009.jpg'
import thr from '../images/banners/IMG-20241021-WA0010.jpg'
import fo from '../images/banners/IMG-20241021-WA0011.jpg'
import fiv from '../images/banners/IMG-20241021-WA0012.jpg'
import six from '../images/banners/IMG-20241021-WA0013.jpg'
import sev from '../images/banners/IMG-20241021-WA0014.jpg'
import nine from '../images/banners/IMG-20241021-WA0015.jpg'
import ten from '../images/banners/IMG-20241021-WA0016.jpg'
import elve from '../images/banners/IMG-20241021-WA0017.jpg'
import twelv from '../images/banners/IMG-20241021-WA0018.jpg'
import thirt from '../images/banners/IMG-20241021-WA0019.jpg'
import fourt from '../images/banners/IMG-20241021-WA0020.jpg'
import Slider from 'react-slick';
import tic from '../images/tikets.png'
import culture from '../images/culture2.jpg'
import jt from '../images/voltsjtculture.jpg'
import run from '../images/albun.jpeg'
import kmomo from '../images/kmomo.jpg'

const PAYMENT_URL = 'https://shop.onemoney.co.zw/eventus/events/transaction/pay'
// const PAYMENT_URL = 'events/transaction/pay'


const Carousel = () => {
  const settings = {
      dots: true,
      arrows: false,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToScroll: 1,
      slidesToShow: 1,
  };

  const [slider, setSlider] = useState(null)

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: '90%', md: '50%' })
  const side = useBreakpointValue({ base: '30%', md: '10px' })

  // These are the images used in the slide
  const cards = [
      `${culture}`,
      `${jt}`,
      `${kmomo}`
  ]


  return (
      <Box position={'relative'} height={'800px'} width={'400px'} overflow={'hidden'}>
          {/* CSS files for react-slick */}
          <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          {/* Left Icon */}
          <IconButton
              aria-label="left-arrow"
              colorScheme="messenger"
              borderRadius="full"
              position="absolute"
              left={side}
              top={top}
              transform={'translate(0%, -50%)'}
              zIndex={2}
              onClick={() => slider?.slickPrev()}>
              <BiLeftArrowAlt />
          </IconButton>
          {/* Right Icon */}
          <IconButton
              aria-label="right-arrow"
              colorScheme="messenger"
              borderRadius="full"
              position="absolute"
              right={side}
              top={top}
              transform={'translate(0%, -50%)'}
              zIndex={2}
              onClick={() => slider?.slickNext()}>
              <BiRightArrowAlt />
          </IconButton>
          {/* Slider */}
          <Slider {...settings} ref={(slider) => setSlider(slider)}>
              {cards.map((url, index) => (
                  // <Box
                  //   key={index}
                  //   height={'6xl'}
                  //   position="relative"
                  //   backgroundPosition="center"
                  //   backgroundRepeat="no-repeat"
                  //   backgroundSize="cover"
                  //   boxSize={'1900px'}
                  //   backgroundImage={`url(${url})`}
                  // />
                  <Image
                      boxSize={{ base: '400px', md: '400px', sm: '100px', lg: '400px' }}
                      objectFit={'cover'}
                      src={`${url}`}
                      padding={2}
                     
                  />
              ))}
          </Slider>
      </Box>
  );
};

function BuyTicket() {
  const [showPassword, setShowPassword] = useState(false)
  const [value, setValue] = React.useState('1')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const location = useLocation()
  const [details, setDetails] = useState(location.state)

  const [ownerName, setOwner] = useState("")
  const [ msisdn, setNumber] =  useState("")
  const [ownerEmail, setEmail] = useState("")
  const [numberOfTickets, setTickets] = useState("")
  const [priceId, setPrice] = useState("")
  const [ eventId, setEvent] =useState("")
  const [loading, setLoading] = useState(false)
  const [chechPhone, setCheckPhone] = useState(false)
  const [receiptData, setReceiptData] = useState([])
  const toast = useToast();
  const navigate = useNavigate();

  const [priceobject, setPriceObj] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  const onPriceChange = (priceId)=>{
    const price = details.body.prices?.filter(item => item.id === priceId);
    setPrice(priceId);
    setPriceObj(price[0]);
  }
  
  // console.log(details.body)
  
  const handleSubmit = async () => {
    setErrorMessage('')
    const res = validate();
    if (!res) {
      toast({
        title: 'Failed',
        description: "Form not complete",
        status: 'error',
        duration: 3000,

      });
      return;
    }
    //  setLoading(true)
    setCheckPhone(true)
    await axios.post(PAYMENT_URL, {
      msisdn: +263 + msisdn,
      ownerEmail,
      ownerName,
      eventId:details.body.id,
      priceId,
      numberOfTickets
     

    }, {
      headers: {
        "Content-type": "application/json",
      }

    })
      .then(response => {
        if (response.data.success) {

          setReceiptData(response.data.body)
          onClose();
          navigate('/receipt-ticket-success',{
            state:response.data.body
          })
          // console.log(response.data.body)
          toast({
            title: 'Online Ticket Purchase',
            description: response.data.message,
            status: 'success',
            position: 'top',
            variant: 'left-accent',
            duration: 9000,

          });

          setCheckPhone(false)
        }

        else {

          setCheckPhone(false)
          toast({
            title: 'Online Ticket Failed',
            description: response.data.message,
            status: 'error',
            position: 'top',
            variant: 'left-accent',
            duration: 3000,

          });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response)
          toast({
            title: "Online Ticket Purchase Failed",
            description: `${error.response.data.message}`,
            status: "error",
            position: "bottom",
            variant: "left-accent",
            duration: 5000,
            isClosable: true,
          });
          //  setLoading(false)
          setCheckPhone(false)
        }else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Online Ticket Purchase Failed",
            description: "Ticket purchase failed, you can try again later",
            status: "error",
            position: 'bottom',
            variant: 'left-accent',
            duration: 8000,
            isClosable: true,
          });
          //  setLoading(false)
          setCheckPhone(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.response.data.message}`,
            status: "error",
            position: 'bottom',
            variant: 'left-accent',
            duration: 8000,
            isClosable: true,
          });

        }
      })
  }

  const [nameerr, setNameErr] =useState('')
  const [emailerr, setEmailErr] =useState('')
  const [msisdnerror, setMsisdnerr] =useState('')
  const [amounterror, setAmounterr] =useState('')
  const [ticketerror, setTicketerr] =useState('')


  const validate = () =>{
    const EMAIL_REGEX = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    const PHONE_REGEX = /^(71)/;
     
    if (!ownerName) {
      setNameErr('Please enter Full name');
      return false;
    }

    if (!ownerEmail) {
      setEmailErr('Please enter email');
      return false;
    }

    if (!msisdn) {
      setMsisdnerr('Please  enter netone number ')
      return false;
    }
    
    if (!priceId) {
      setAmounterr('Please  select ticket ')
      return false;
    }
    
    if (!numberOfTickets) {
      setTicketerr('Please enter number of tickets eg. 1 ')
      return false;
    }
    if (!EMAIL_REGEX.test(ownerEmail)) {
      setEmailErr('email not valid');
      return false;
    }
    if (msisdn.length < 9) {
      setMsisdnerr('phone number invalid');
      return false;
    }
    if (msisdn.length > 10) {
      setMsisdnerr('phone number invalid');
      return false;
    }
    if (!PHONE_REGEX.test(msisdn)) {
      setMsisdnerr('please enter netone onemoney number');
      return false;
    }
  
    return true;
  }



// console.log(details)
 
  return (
    <>
      <NavBar />
      
      <Breadcrumb m={6} fontWeight='medium' fontSize='sm'>
        <BreadcrumbItem>
          <BreadcrumbLink href='/'>Home</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='/culture-fest'>263 Culture Fest</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box m={2} overflow="hidden" width="100%"  >
                <marquee behavior="scroll" direction="left" scrollamount="18">
                    <Flex>
                    {/* <Image
                            src={twelv}
                            mx={4}
                            boxSize="100px"
                            objectFit="contain"
                        /> */}

                        <Image
                            src={nine}
                            mx={4}
                            boxSize="100px"
                            objectFit="contain"
                        />

                        <Image
                            src={one}
                            mx={4}
                            boxSize="100px"
                            objectFit="contain"

                        />
                        <Image
                            src={two}
                            mx={4}
                            boxSize="100px"
                            objectFit="contain"

                        />
                        <Image
                            src={thr}
                            mx={4}
                            boxSize="100px"

                            objectFit="contain"

                        />
                        <Image
                            src={fo}
                            mx={4}
                            boxSize="100px"
                            objectFit="contain"
                        />
                        <Image
                            src={fiv}
                            mx={4}
                            boxSize="100px"
                            objectFit="contain"
                        />
                        <Image
                            src={six}
                            mx={4}
                            boxSize="100px"
                            objectFit="contain"
                        />
                        <Image
                            src={sev}
                            mx={4}
                            boxSize="100px"
                            objectFit="contain"
                        />
                      
                        <Image
                            src={ten}
                            mx={4}
                            boxSize="100px"
                            objectFit="contain"
                        />
                        <Image
                            src={elve}
                            mx={4}
                            boxSize="100px"
                            objectFit="contain"
                        />
                      
                        <Image
                            src={thirt}
                            mx={4}
                            boxSize="100px"
                            objectFit="contain"
                        />
                        <Image
                            src={fourt}
                            mx={4}
                            boxSize="100px"
                            objectFit="contain"
                        />
                    </Flex>
                </marquee>
            </Box>

           <Stack
                mt={-3}
                textAlign={'center'}
                align={'center'}
                spacing={{ base: 4, md: 8 }}
                py={{ base: 4, md: 6 }}
            >
                <Flex align='center' justify='space-between' p={5}>       
            <Box ml={5} mr={4}>


<Heading
    mb={4}
    fontWeight={600}
    fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}
    lineHeight={'110%'}>
    <Text as={'span'} color={'orange.400'}>
      OneMoney
    </Text>
   {''} Online Tickets
</Heading>
<Text color={'gray.500'} maxW={'2xl'} >
  Buy Advance Tickets Now
</Text>
          </Box>
          </Flex>
          </Stack>
      <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
        <Flex p={8} flex={2} align={'flex-start'} justify={'center'} pt={0}>
          <Stack spacing={4} w={'full'} maxW={'xl'} py={12} px={2}>
            <Heading fontSize={'xl'} textAlign={'center'}>Ticket Purchase</Heading>
            <Box
              rounded={'lg'}
              bg={useColorModeValue('white', 'gray.700')}
              boxShadow={'lg'}
              p={8}>
              <Stack spacing={4}>
                <HStack>

                  <FormControl id="firstName" isRequired>
                    <FormLabel>Full Name</FormLabel>
                    <Input type="text" name='ownerName' value={ownerName} onChange={(e)=>setOwner(e.target.value)}
                    onFocus={() => { setNameErr('') }} borderColor={nameerr ? 'red.400' : 'gray.300'}
                    />
                    <Text color={'red'}>{nameerr}</Text>
                  </FormControl>


                </HStack>

                <FormControl id="email" isRequired>
                  <FormLabel>Email address</FormLabel>
                  <Input type="email" name='ownerEmail' value={ownerEmail}  onChange={(e)=>setEmail(e.target.value)} 
                  onFocus={() => { setEmailErr('') }} borderColor={emailerr ? 'red.400' : 'gray.300'}
                  />
                  <Text color={'red'}>{emailerr} </Text>
                </FormControl>
                <FormControl id="phoneNumber" isRequired>
                  <FormLabel>Enter OneMoney Phone Number</FormLabel>
                  <InputGroup>
                    <InputLeftAddon children='+263' />
                    <Input
                      type="text"
                      placeholder='eg.7100000000'
                      name='msisdn'
                      value={msisdn}
                      onChange={(e)=>setNumber(e.target.value)}
                      onFocus={() => { setMsisdnerr('') }} borderColor={msisdnerror ? 'red.400' : 'gray.300'}
                    />
                  </InputGroup>
                  <Text color={'red'}>{msisdnerror}</Text>
                </FormControl>

                <HStack spacing={1}>

                <FormControl>
                    <FormLabel>Amount</FormLabel> 
                    <Select 
              _hover={{
                borderRadius: 'orange',
              }} 
              placeholder='Select Ticket'
               name='priceId'
              onChange={(e)=>onPriceChange(e.target.value)}
              onFocus={() => { setAmounterr('') }} borderColor={amounterror ? 'red.400' : 'gray.300'}
              >
      {details.body?.prices?.length === 0 && <Spinner />}
      {details.body?.prices?.map((userOrg) => (
        <option key={userOrg.id} value={userOrg.id}>
          USD${`${userOrg.amount} - ${userOrg.name}`}
        </option>
      ))}
    
      </Select>
      <Text color={'red'}>{amounterror} </Text>
        </FormControl>

                </HStack>
                <HStack spacing={1}>
                  <FormControl>
                <FormLabel>Number of Tickets</FormLabel>
                  <Input type='number' value={numberOfTickets} name='numberOfTickets' placeholder='eg. 1' onChange={(e)=>setTickets(e.target.value)} w={'auto'}
                  onFocus={() => { setTicketerr('') }} borderColor={ticketerror ? 'red.400' : 'gray.300'}
                  />
                  <Text color={'red'}> {ticketerror}</Text>
                    </FormControl>
                  </HStack>
                  {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            }
                <Stack spacing={10} pt={2}>
                  <Button
                    onClick={onOpen}
                    loadingText="Submitting"
                    size="lg"
                    bg={'orange.400'}
                    color={'white'}
                    _hover={{
                      bg: 'black',
                    }}>
                    Submit
                  </Button>
                </Stack>
                {/* <Stack pt={6}>
              <Text align={'center'}>
                Already a user? <Link color={'blue.400'}>Login</Link>
              </Text>
            </Stack> */}

                <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} size={'sm'}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Confirm Online Payment</ModalHeader>
                    <ModalCloseButton />
                    {chechPhone ? (
              <Flex align='center' m={4} justify={'center'} direction={'column'}>
                <Spinner
                  thickness='4px'
                  speed='0.85s'
                  emptyColor='gray.200'
                  color='orange.300'
                  size='xl'
                />
                <Text mt={2} color={'gray.800'} fontWeight={'semibold'}>...processing, please check your phone to enter pin</Text>
              </Flex>
            ) : (
              <>
                {/* <Text>Try Again later</Text> */}
              </>
            )}
                   
                    {loading ? (
              <Flex align='center' m={4} justify={'center'} direction={'column'}>
                <Spinner
                  thickness='4px'
                  speed='0.85s'
                  emptyColor='gray.200'
                  color='orange.300'
                  size='xl'
                />
                <Text mt={2} color={'gray.800'} fontWeight={'semibold'}>...processing payment</Text>

              </Flex>
            ) : (
              <ModalBody>

                <Box
                  bg={'gray.50'}
                  rounded='lg'
                  boxShadow={'sm'}
                >


                  <Stack
                    textAlign={'center'}
                    p={6}
                    // color={useColorModeValue('gray.800', 'white')}
                    color={'gray.800'}
                    align={'center'}>
                    <Text
                      fontSize={'sm'}
                      fontWeight={500}
                      // bg={useColorModeValue('green.50', 'green.900')}
                      bg={'orange.200'}
                      p={2}
                      px={3}
                      color={'white'}
                      rounded={'full'}>
                      Amount
                    </Text>
                    <Stack direction={'row'} align={'center'} justify={'center'}>
                      <Text fontSize={'3xl'}>$USD</Text>
                      <Text fontSize={'6xl'} fontWeight={800}>
                        
                        {(priceobject?.amount * numberOfTickets).toFixed(2)}
                      </Text>
                    </Stack>
                  </Stack>
                </Box>
                <Text mt={3} fontWeight='semi-bold'>
                  Please confirm the following payment details below:
                </Text>
                <VStack align='left' m={1} spacing={4}>
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                      Customer Name:
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      {ownerName}
                      {nameerr}
                    </Text>
                  </Box>
                  <Divider orientation='horizontal' />
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                      OneMoney:
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      263{msisdn}
                    </Text>
                  </Box>
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                      Email:
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      {ownerEmail }
                    </Text>
                  </Box>
                  <Divider orientation='horizontal' />
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                      Number of tickets:
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      {numberOfTickets}
                    </Text>
                  </Box>
                  <Divider orientation='horizontal' />
                </VStack>
                <Stack align={'center'} m={4}>

                  <Button
                    isLoading={chechPhone}
                    loadingText='Loading'
                    variant='solid'
                    spinnerPlacement='end'
                    w={'250px'}
                    onClick={() => {
                      handleSubmit()

                    }

                    }
                    bg={'#FF7300'}
                    color={'white'}
                    _hover={{
                      bg: 'black',
                      color: 'white'
                    }}>
                    Proceed
                  </Button>
                </Stack>



                </ModalBody>
            )}

           
                   

                    {/* <ModalFooter>
                      <Button colorScheme='red' mr={3} onClick={onClose}>
                        Back
                      </Button>
                      <RouterLink to="/receipt">
                        <Button colorScheme='green'>Proceed to payment</Button>
                      </RouterLink>
                    </ModalFooter> */}
                  </ModalContent>
                </Modal>
              </Stack>
            </Box>


          </Stack>
        </Flex>
        <Flex flex={2} ml={8} mt={12}>
          {/* <Image
            alt={'Login Image'}
            objectFit={'cover'}
            // style={{ width: '100%', height: '650px' }}
            boxSize={{ base: '400px', md: '400px', sm: '200px', lg: '400px' }}
            src={
              culture
            }
          /> */}
  
          <Carousel />

        </Flex>
      </Stack>


    </>
  )
}

export default BuyTicket






