import React from 'react'
import NavBar from '../pages/Navbar'
import {
    Button,
    Checkbox,
    Flex,
    Text,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    Image,
    InputGroup,
    ViewIcon,
    Box,
    Link,
    HStack,
    useColorModeValue,
    InputRightElement,
    InputLeftAddon,
    Select,
    useDisclosure,
    ModalBody,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalContent,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    ModalCloseButton,
    ModalOverlay,
    onOpen,
    isOpen,
    Divider,
    Spinner,
    VStack,
    onClose,
    useToast

   
  } from '@chakra-ui/react'
  import { useState } from 'react'
  import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
  import { AddIcon, SearchIcon } from '@chakra-ui/react';
  import albun from '../images/albun.jpeg'
  import axios from '../../api/axios'
  import { Radio, RadioGroup } from '@chakra-ui/react'


// const PAYMENT_URL = 'https://shop.onemoney.co.zw/eventus/events/transaction/pay'
const PAYMENT_URL = 'eventus/events/transaction/pay'


function BuyTicket() {
    
  const btnRef = React.useRef()
  const [value, setValue] = React.useState('1')
  const location = useLocation()
  const [details, setDetails] = useState(location.state)
  const { isOpen, onOpen, onClose } = useDisclosure()

  console.log(details)

  const [ownerName, setOwner] = useState("")
  const [ msisdn, setNumber] =  useState("")
  const [ownerEmail, setEmail] = useState("")
  const [numberOfTickets, setTickets] = useState("")
  const [priceId, setPrice] = useState("")
  const [ eventId, setEvent] =useState("")
  const [loading, setLoading] = useState(false)
  const [chechPhone, setCheckPhone] = useState(false)
  const [receiptData, setReceiptData] = useState([])
  const toast = useToast();
  const navigate = useNavigate();

  const [priceobject, setPriceObj] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  const [club, setClub] = useState('');
  const [amount, setAmount] = useState('');
const [DOB, setDOB] = useState('');
const [Gender, setGender] = useState('');
const [tShirtSize, setTShirtSize] = useState('');
const [Group, setGroup] = useState('');
const [totalAmount, setTotalAmount] = useState(0);

const onPriceChange = (priceId) => {
    const price = details.body.prices?.find(item => item.id === priceId);
    setPrice(priceId);
    setPriceObj(price || {});
    calculateTotalAmount(); // Recalculate when price changes
  };
  
  const calculateTotalAmount = () => {
    const ticketPrice = priceobject?.amount || 0;
    const tickets = parseInt(numberOfTickets, 10) || 0; // Convert to a number with a fallback
    setTotalAmount((ticketPrice * tickets).toFixed(2));
  }

  const handleOpenModal = () => {
    calculateTotalAmount() 
    onOpen()
  }

  const handleNumberOfTicketsChange = (e) => {
    setTickets(e.target.value);
    calculateTotalAmount(); // Recalculate when ticket number changes
  };

  console.log(details[0].prices[0].id)

  const handleSubmit = async () => {
    setErrorMessage('');
    const res = validate();
    if (!res) {
      toast({
        title: 'Failed',
        description: "Form not complete",
        status: 'error',
        duration: 3000,
      });
      return;
    }
  
    setCheckPhone(true);
    try {
      const response = await axios.post(PAYMENT_URL, {
        msisdn: `263${msisdn}`,
        ownerEmail,
        ownerName,
        eventId: details[0].id,
        priceId: details[0].prices[0].id,
        numberOfTickets: 1,
        extraDetails: {
          club,
          DOB,
          Gender,
          Group,
        ['T Shirt Size']: tShirtSize
        }
      }, {
        headers: {
          "Content-type": "application/json",
        }
      });
  
      if (response.data.success) {
        setReceiptData(response.data.body);
        onClose();
        navigate('/receipt-ticket-success', { state: response.data.body });
        toast({
          title: 'Online Ticket Purchase',
          description: response.data.message,
          status: 'success',
          position: 'top',
          variant: 'left-accent',
          duration: 9000,
        });
        setCheckPhone(false);
      } else {
        setCheckPhone(false);
        toast({
          title: 'Online Ticket Failed',
          description: response.data.message,
          status: 'error',
          position: 'top',
          variant: 'left-accent',
          duration: 3000,
        });
      }
    } catch (error) {
      setCheckPhone(false);
      handleAxiosError(error);
    }
  };
  
  function handleAxiosError(error) {
    if (error.response) {
      toast({
        title: "Online Ticket Purchase Failed",
        description: error.response.data.message,
        status: "error",
        position: "bottom",
        variant: "left-accent",
        duration: 5000,
        isClosable: true,
      });
    } else if (error.request) {
      toast({
        title: "Online Ticket Purchase Failed",
        description: "Ticket purchase failed, you can try again later",
        status: "error",
        position: 'bottom',
        variant: 'left-accent',
        duration: 8000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Network Error",
        description: `Error: ${error.message}`,
        status: "error",
        position: 'bottom',
        variant: 'left-accent',
        duration: 8000,
        isClosable: true,
      });
    }
  }
    const [nameerr, setNameErr] =useState('')
    const [emailerr, setEmailErr] =useState('')
    const [msisdnerror, setMsisdnerr] =useState('')
    const [amounterror, setAmounterr] =useState('')
    const [ticketerror, setTicketerr] =useState('')
  
  
    const validate = () =>{
      const EMAIL_REGEX = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
      const PHONE_REGEX = /^(71)/;
       
      if (!ownerName) {
        setNameErr('Please enter Full name');
        return false;
      }
  
      if (!ownerEmail) {
        setEmailErr('Please enter email');
        return false;
      }
  
      if (!msisdn) {
        setMsisdnerr('Please  enter netone number ')
        return false;
      }
      
    //   if (!priceId) {
    //     setAmounterr('Please  select ticket ')
    //     return false;
    //   }
   
      
    //   if (!numberOfTickets) {
    //     setTicketerr('Please enter number of tickets eg. 1 ')
    //     return false;
    //   }
      
     
      if (!EMAIL_REGEX.test(ownerEmail)) {
        setEmailErr('email not valid');
        return false;
      }
      if (msisdn.length < 9) {
        setMsisdnerr('phone number invalid');
        return false;
      }
      if (msisdn.length > 10) {
        setMsisdnerr('phone number invalid');
        return false;
      }
      if (!PHONE_REGEX.test(msisdn)) {
        setMsisdnerr('please enter netone onemoney number');
        return false;
      }
    
      return true;
    }
  
  

  return (
    <>
    <NavBar />
        <Breadcrumb m={6} fontWeight='medium' fontSize='sm'>
        <BreadcrumbItem>
          <BreadcrumbLink href='/'>Home</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='/admin/user-management.app'>Albun Marathon</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
        <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
        <Flex p={8} flex={1} align={'flex-start'} justify={'center'} pt={0}>
        <Stack spacing={4} w={'600px'} maxW={'2xl'}  py={12} px={6}>
          <Heading fontSize={'xl'}  textAlign={'center'}>Register Albun Marathon</Heading>
          <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <HStack>
              
                <FormControl id="firstName" isRequired>
                  <FormLabel>Enter Full Name</FormLabel>
                  <Input type="text" name='ownerName' value={ownerName} onChange={(e)=>setOwner(e.target.value)}
                    onFocus={() => { setNameErr('') }} borderColor={nameerr ? 'red.400' : 'gray.300'}
                    />
                    <Text color={'red'}>{nameerr}</Text>
                </FormControl>
               
            </HStack>
            
            <FormControl id="email" isRequired>
                  <FormLabel>Email address</FormLabel>
                  <Input type="email" name='ownerEmail' value={ownerEmail}  onChange={(e)=>setEmail(e.target.value)} 
                  onFocus={() => { setEmailErr('') }} borderColor={emailerr ? 'red.400' : 'gray.300'}
                  />
                  <Text color={'red'}>{emailerr} </Text>
                </FormControl>
                <FormControl id="phoneNumber" isRequired>
                  <FormLabel>Enter OneMoney Phone Number</FormLabel>
                  <InputGroup>
                    <InputLeftAddon children='+263' />
                    <Input
                      type="text"
                      placeholder='eg.7100000000'
                      name='msisdn'
                      value={msisdn}
                      onChange={(e)=>setNumber(e.target.value)}
                      onFocus={() => { setMsisdnerr('') }} borderColor={msisdnerror ? 'red.400' : 'gray.300'}
                    />
                  </InputGroup>
                  <Text color={'red'}>{msisdnerror}</Text>
                </FormControl>

                
                <FormControl id="firstName" isRequired>
                  <FormLabel>Club</FormLabel>  
                   <Input 
                    type="text" 
                    placeholder='e.g., individual or club you belong to'
                    value={club} 
                    onChange={(e) => setClub(e.target.value)}
                  />
               
                </FormControl>



                <HStack>
               {/* <FormControl id="firstName" isRequired>
                  <FormLabel>Amount</FormLabel>  
                   <Input 
                    type="number" 
                    placeholder=' USD'
                    value={amount} 
                    onChange={(e) => setAmount(e.target.value)}
                  />
                
               
                </FormControl> */}
                {/* <FormControl>
                <FormLabel>Number of Tickets</FormLabel>
                  <Input 
                  
                  type="number" 
                    value={numberOfTickets} 
                    onChange={handleNumberOfTicketsChange} 
                   name='numberOfTickets'
                    placeholder='e.g 1'
                    
                  onFocus={() => { setTicketerr('') }} 
                  borderColor={ticketerror ? 'red.400' : 'gray.300'}
                  />
                  <Text color={'red'}> {ticketerror}</Text>
                </FormControl> */}
                </HStack>

                

            <HStack>
           
              <FormControl id="email" isRequired>
            <FormLabel>D.O.B</FormLabel>
            <Input 
                type="date" 
                placeholder='dd/mm/yy'
                value={DOB} 
                onChange={(e) => setDOB(e.target.value)}
              />
            </FormControl>

 
            </HStack>
            <HStack w={'300px'}>
             
            <Box>
              <FormLabel fontSize={{ base: 'sm', sm: 'sm', md: 'lg'}}>T-Shirt Size</FormLabel>
              <Select placeholder='Size' value={tShirtSize} onChange={(e) => setTShirtSize(e.target.value)} isRequired>
                <option value='Small'>Small</option>
                <option value='Large'>Large</option>
                <option value='Medium'>Medium</option>
                <option value='XL'>XL</option>
                <option value='2XL'>2XL</option>
                <option value='3XL'>3XL</option>
                
            </Select>
            </Box>
            <Box>
              <FormLabel fontSize={{ base: 'sm', sm: 'sm', md: 'lg'}}>Choose Group (km)</FormLabel>
              <Select placeholder='Choose Distance' value={Group} onChange={(e) => setGroup(e.target.value)} isRequired>
                <option value='5 KM'>5 KM</option>
                <option value='10 KM'>10 KM</option>
                <option value='21 KM'>21 KM</option>
                <option value='42 KM'>42 KM</option>


                
            </Select>
            </Box>

        <Box>
        <FormLabel mb={2} fontSize={{ base: 'sm', sm: 'sm', md: 'lg'}}>Select Gender</FormLabel>
          <RadioGroup onChange={(value) => setGender(value)} value={Gender} isRequired>
          <Stack direction='row'>
          <Radio value='Male' colorScheme="orange">Male</Radio>
          <Radio value='Female' colorScheme="orange">Female</Radio>
         </Stack>
         </RadioGroup>
        </Box>

                
              
              
            </HStack>
            
           
            <Stack spacing={10} pt={2}>
              <Button
                onClick={onOpen}
                loadingText="Submitting"
                size="lg"
                bg={'orange.400'}
                color={'white'}
                _hover={{
                  bg: 'black',
                }}>
                Click to pay
              </Button>
            </Stack>
            

            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} size={'lg'}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Confirm Online Payment Details</ModalHeader>
                    <ModalCloseButton />
                    {chechPhone ? (
              <Flex align='center' m={4} justify={'center'} direction={'column'}>
                <Spinner
                  thickness='4px'
                  speed='0.85s'
                  emptyColor='gray.200'
                  color='orange.300'
                  size='xl'
                />
                <Text mt={2} color={'gray.800'} fontWeight={'semibold'}>...processing, please check your phone to enter pin</Text>
              </Flex>
            ) : (
              <>
       
              </>
            )}
                   
                    {loading ? (
              <Flex align='center' m={4} justify={'center'} direction={'column'}>
                <Spinner
                  thickness='4px'
                  speed='0.85s'
                  emptyColor='gray.200'
                  color='orange.300'
                  size='xl'
                />
                <Text mt={2} color={'gray.800'} fontWeight={'semibold'}>...processing payment</Text>

              </Flex>
            ) : (
              <ModalBody>

                <Box
                  bg={'gray.50'}
                  rounded='lg'
                  boxShadow={'sm'}
                >


                  <Stack
                    textAlign={'center'}
                    p={6}
                   
                    color={'gray.800'}
                    align={'center'}>
                    <Text
                      fontSize={'sm'}
                      fontWeight={500}
                     
                      bg={'orange.200'}
                      p={2}
                      px={3}
                      color={'white'}
                      rounded={'full'}>
                      Amount
                    </Text>
                    <Stack direction={'row'} align={'center'} justify={'center'}>
                      <Text fontSize={'3xl'}>$USD</Text>
                      <Text fontSize={'6xl'} fontWeight={800}>
                      {details[0].prices[0].amount}

                      </Text>
                    </Stack>
                  </Stack>
                </Box>
              
                <VStack align='left' m={1} spacing={4}>
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                      Customer Name:
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      {ownerName}
                      {nameerr}
                    </Text>
                  </Box>
                  <Divider orientation='horizontal' />
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                      OneMoney:
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      263{msisdn}
                    </Text>
                  </Box>
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                      Email:
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      {ownerEmail }
                    </Text>
                  </Box>
                  <Divider orientation='horizontal' />
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                      Club:
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      {club}
                    </Text>
                  </Box>
                  <Divider orientation='horizontal' />
                  
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                      D.O.B
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      {DOB}
                    </Text>
                  </Box>
                  <Divider orientation='horizontal' />
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                      Gender
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      {Gender}
                    </Text>
                  </Box>
                  <Divider orientation='horizontal' />
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                      T-Shirt Size
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      {tShirtSize}
                    </Text>
                  </Box>
                  <Divider orientation='horizontal' />
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                      Group (KM)
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      {Group}
                    </Text>
                  </Box>
                  <Divider orientation='horizontal' />
                  <Box>
                    <Heading as="h1" fontSize="md" color={'#FF7300'}>
                    Number of Tickets
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                      {1}
                    </Text>
                  </Box>
                </VStack>
                <Stack align={'center'} m={4}>

                    <Button
                      isLoading={chechPhone}
                      loadingText='Loading'
                      variant='solid'
                      spinnerPlacement='end'
                      w={'250px'}
                      onClick={() => {
                        handleSubmit()

                      }

                      }
                      bg={'#FF7300'}
                      color={'white'}
                      _hover={{
                        bg: 'black',
                        color: 'white'
                      }}>
                      Proceed
                    </Button>
                    </Stack>



                </ModalBody>
            )}

           
                   

                    
                  </ModalContent>
                </Modal>
          </Stack>
        </Box>
          
        
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          alt={'Login Image'}
          objectFit={'cover'}
          padding={2}
          boxSize={{ base: '400px', sm: '400px', md: '500px', lg: '600px', xl: '600px' }}
          src={
            albun
          }
        />
      </Flex>
    </Stack>

        
    </>
  )
}

export default BuyTicket





                 
