import React, { useRef } from 'react';
import {
  Box,
  Flex,
  Avatar,
  useColorModeValue,
  HStack,
  Text,
  Button,
  Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
} from '@chakra-ui/react';
import { QRCodeCanvas } from 'qrcode.react'; // Importing QRCodeCanvas
import jsPDF from 'jspdf'; // Importing jsPDF
import html2canvas from 'html2canvas'; // Importing html2canvas
import oneMoney from '../images/c2.png';
import image from '../images/ALBUNK.png';
import culture from '../images/culturebg.png';
import bg from '../images/b8.jpeg';
import netone from '../images/netonebg.png'
import al from '../images/albunl.jpeg'

const AlbunReceipt = ({ orderNumber, date, amount }) => {
  const receiptRef = useRef(); // Reference to the receipt div

  // QR Code data - can be customized based on your needs
  const qrData = JSON.stringify({
    orderNumber,
    date,
    amount,
  });

  // Function to download the receipt as PDF
  const downloadReceipt = () => {
    const receiptElement = receiptRef.current;
    html2canvas(receiptElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`receipt_${orderNumber}.pdf`);
    });
  };

  return (
    <>
    
      {/* Button moved to the top */}
      <Box position="absolute" top={4} right={4}>
        <Button colorScheme="green" onClick={downloadReceipt}>
          Download Receipt
        </Button>
      </Box>

      {/* Navbar - aligned to the left */}
      <Box
        bg={useColorModeValue('gray.100', 'gray.900')}
        px={12}
        py={4} // Added padding
        shadow={'xl'}
        border={'1px solid'}
        bgColor={useColorModeValue('whiteAlpha.100')}
        borderColor={useColorModeValue('gray.50', 'gray.50')}
        rounded={'lg'}
        w={'100%'}
        mb={6} // Added margin at the bottom
      >
        <Flex alignItems={'center'} justifyContent={'flex-start'}> {/* Align to the left */}
          <HStack spacing={4}>
            <Box>
              <Avatar size={'xl'} src={oneMoney} />
            </Box>
            <Box>
              <Text
                fontSize={{ base: '1xl', sm: 'lg', md: 'xl' }}
                color='#FF7300'
                fontWeight={'semibold'}
              >
                OneMoney Services
              </Text>
            </Box>
          </HStack>
        </Flex>
      </Box>
      <Breadcrumb m={6} fontWeight='medium' fontSize='sm'>
        <BreadcrumbItem>
          <BreadcrumbLink href='/'>Home</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='/admin/user-management.app'>Albun Ticket</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      {/* The receipt content that will be downloaded */}
      <div ref={receiptRef} style={styles.container}>
      
        <Flex alignItems={'center'} justifyContent={'center'}>
        
          {/* Centering Flex */}
          <HStack spacing={4}>
            {/* Images in the same row with fixed size */}
            
            <Box>
              <img src={image} alt="Culture Logo" style={styles.image} />
            </Box>
          </HStack>
        </Flex>

        {/* Added margin above Payment Receipt */}
        <h2 style={styles.header}><u>Payment Receipt</u></h2>
        <div style={styles.details}>
          <p>
            <strong>Name:</strong> {date}
          </p>
          <p>
            <strong>Email:</strong> {orderNumber}
          </p>
          <p>
            <strong>Phone #:</strong> {amount}
          </p>
        </div>

        <div style={styles.qrCode}>
          <h3><u>Admit One</u></h3>
          <QRCodeCanvas value={qrData} size={150} />
        </div>

        {/* Footer image */}
        {/* <div style={styles.footer}>
          <img src={al} alt="Footer Background" style={styles.footerImage} />
        </div> */}
      </div>
    </>
  );
};

const styles = {
    container: {
      border: '1px solid #000',
      padding: '20px',
      maxWidth: '400px',
      margin: '0 auto',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f5f5f5',
      paddingBottom: '20px', // Added padding for the receipt
    },
    header: {
      textAlign: 'center',
      marginBottom: '20px',
      marginTop: '20px', // Added margin at the top of the header to create space
    },
    details: {
      marginBottom: '20px',
    },
    qrCode: {
      textAlign: 'center',
    },
    footer: {
      marginTop: '20px',
      textAlign: 'center',
    },
    footerImage: {
      width: '100%',      // Make the image span 100% of the receipt's width
      maxHeight: '80px',  // Decreased the height of the image
      objectFit: 'cover', // Ensures the image covers the width while keeping the aspect ratio
      marginBottom: '0px',
    },
    image: {
      width: '150px', // Adjust the width of the images as needed
      height: 'auto',
      objectFit: 'contain', // Ensures the image fits well within its container
    },
  };

export default AlbunReceipt;