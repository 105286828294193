import React, { forwardRef, useEffect, useRef, useState } from 'react'
import pic from '../images/ath.jpg'
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Center, Divider, Flex, Grid, HStack, Heading, Image, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react'
import QRCode from 'react-qr-code'
import { QRCodeCanvas } from 'qrcode.react'; // Importing QRCodeCanvas
import jsPDF from 'jspdf'; // Importing jsPDF
import html2canvas from 'html2canvas'; // Importing html2canvas
import NavBar from '../pages/Navbar'
import culture from '../images/c2-removebg.png';
import bg from '../images/b8.jpeg';
import netone from '../images/netonebg.png'
// import pic from '../images/culture.jpeg'
import back from '../images/backg.jpg'
import { useLocation } from 'react-router-dom';
import axios from '../../api/axios';

const EVENTS = 'eventus/events'


  const StatsCard = forwardRef((props,ref)=> {
    const {title,location, time, buyer,code, kilo,club,customer,fee, feename } = props;
    return (
        <Box
      ref={ref}
            width="380px"
            height="auto"
            bgImage={pic} // Faint background image
            bgPosition="center"
            bgSize="cover"
            bgRepeat="no-repeat"
            backgroundBlendMode="darken"
            borderRadius="lg"
            boxShadow="lg"
            p={6}
            position="relative"
        >
            <Flex justifyContent="space-around" mb={4}>
                <Image src={netone} alt="Logo 1" boxSize="50px" />
                <Image src={culture} alt="Logo 2" boxSize="50px" />
            </Flex>

            <Flex justifyContent="flex-start" mb={4}>
                {/* <Image
                    src={pic}
                    alt="Artist"
                    boxSize="250px"
                    objectFit="cover"
                    boxShadow={'base'}
                /> */}
                <VStack>

                {code}
                <Text fontSize={'sm'}  fontWeight={'semibold'}>Admit One</Text>
                </VStack>

            </Flex>
            <Divider />



            <Stack mt='2' align={'center'} spacing='1' bgColor={'whiteAlpha.800'}>
                <Heading size={{base: 'sm', sm:'sm', lg:'md' }} color={'#FF7300'} >{title}</Heading>
                <Text fontSize={'md'} fontWeight={'semibold'}>
                   {location}
                </Text>
                <Text fontSize={'md'} fontWeight={'semibold'}>
                   {feename}: $USD{fee}
                </Text>
                <Text fontSize='sm'>
                    {time}
                </Text>
            </Stack>
            <Divider />
            <Flex justifyContent="center" mt="auto" flex={1} >

                <VStack>

                    {/* <Text mt={2} fontWeight={'semibold'}>Admit One</Text>
                  
                        {code} */}

                    <HStack>
                       
                        {/* <Text fontSize='sm'>
                            Email: {buyer}
                        </Text> */}

                    </HStack>

                    {/* <Image src={bg} objectFit={'fill'} /> */}
                </VStack>

            </Flex>
            <Stack direction={'column'} spacing='1' mt={4} bgColor={'whiteAlpha.800'}>
            <Heading textAlign={'center'} size={{base: 'sm', sm:'sm', lg:'md' }} color={'#FF7300'} >Event Details:</Heading>
            <Stack direction={'row'}>

                        <Text ml={1} fontSize={'sm'} fontWeight={'semibold'}>Full Name: {customer}</Text>
                        </Stack>
                <Stack direction={'row'}>

                <Text ml={1} fontSize={'sm'} fontWeight={'semibold'}>Running:{kilo}</Text>
                <Text fontSize={'sm'} fontWeight={'semibold'}>Club:{club}</Text>
                </Stack>

            </Stack>

        </Box>
       


    );
  })

function ReceiptTicket(id) {
    const [eventId, setEvent] = useState('')
    const [eventData, setEventData]= useState([])
    const location = useLocation()
  const [details, setDetails] = useState(location.state)
  const [loading,setLoading] = useState(false)
  const [downloaded, setDownloaded] = useState(0)
  const ticketRefs = useRef({})
    // Reference to the receipt div

    // QR Code data - can be customized based on your needs
    // const qrData = details.map((item)=> item.ticketId)
    // console.log(receiptRef)

    const getDetails = async () => {
        try {
         
          setLoading(true)
          const eventId = details[0].price.eventId
          const response = await axios.get(`${EVENTS}/${eventId}`, {
    
          })
          if (response.data.success === true) {
            setEventData(response.data.body)
            console.log(response.data.body)
            setLoading(false)
          }
        } catch (error) {
        //   console.log(error);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // toast({
            //   title: "Error",
            //   description: `Server responded with ${error.message}`,
            //   status: "error",
            //   duration: 5000,
            //   isClosable: true,
            // });
          } else {
            // Something happened in setting up the request that triggered an Error
    
    
          }
        }
      }
    
      useEffect(() => {
        getDetails();
        // setTimeout(() => {
    
        // }, 6000);
    
      }, [])
      
 

     // Function to download the receipt as PDF
  const downloadReceipt = (index) => {
    setLoading(true)
    const receiptElement =  ticketRefs.current[index];
    // console.log(receiptElement)
    html2canvas(receiptElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        format: [90, 40]
      });
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`ticket.pdf`);
      setLoading(false)
    });
  };

  useEffect(() => {
 
  
    details.forEach((item)=>{
                                   
        downloadReceipt(item.ticketId)
    })
  

  }, [])

  function formatDate(dateString) {
    // Parse the input date string
    const date = new Date(dateString);
  
    // Format the date as desired (e.g., "November 23, 2024")
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(date);
  }
  
  console.log(details)
    return (
        <>
            <NavBar />
        
    
            <Flex align={'center'} justify={'center'} mt={2}>
            <Button isLoading={loading} loadingText="Downloading ... "  colorScheme="green" onClick={()=>{
              
                                 details.forEach((item)=>{
                                   
                                     downloadReceipt(item.ticketId)
                                 })
                                
                                  }}>
                                  Download Ticket
                                </Button>
            </Flex>
            <Center mt={4}>
                
              <Stack direction={'column'}>
                {details.map((data,index)=>{
            
                    return(

                      <>
                        <StatsCard
                        key={index}
                                 ref={(ref)=> (ticketRefs.current[data.ticketId] = ref)}
                                  title={data.eventName}
                                  location={data.eventLocation}
                                //  time={formatDate(eventData?.date)}
                                  code={
                                       <QRCode
                                  size={75}
                                  value={data.ticketId} /> 
                                  }
                                 buyer={data.ownerEmail}
                                 kilo={data.extraDetails.Group}
                                 club={data.extraDetails.club}
                                 customer={data.ownerName}
                                 feename={data.price.name}
                                 fee={data.price.amount}
                                  />
                                 
                      </>
                    )
                })}
               
                {/* <Box
                ref={receiptRef}
                    width="380px"
                    height="auto"
                    bgImage={back} 
                    bgPosition="center"
                    bgSize="cover"
                    bgRepeat="no-repeat"
                    backgroundBlendMode="lighten"
                    borderRadius="lg"
                    boxShadow="lg"
                    p={6}
                    position="relative"
                >
                   
                    <Flex justifyContent="space-between" mb={4}>
                        <Image src={netone} alt="Logo 1" boxSize="50px" />
                        <Image src={culture} alt="Logo 2" boxSize="50px" />
                    </Flex>

                   
                    <Flex justifyContent="center" mb={4}>
                        <Image
                            src={pic}
                            alt="Artist"
                            boxSize="250px"
                            objectFit="cover"
                            boxShadow={'base'}
                        />
                    </Flex>
                    <Divider />



                    <Stack mt='2' align={'center'} spacing='1'>
                        <Heading size='md' color={'#FF7300'} >Culture Fest</Heading>
                        <Text fontSize={'md'} fontWeight={'semibold'}>
                            Belgravia Sports club
                        </Text>
                        <Text fontSize='sm'>
                            Saturday 26 November 2024 10:00am
                        </Text>
                    </Stack>
                    <Divider />
                 
                    <Flex justifyContent="center" mt="auto" flex={1} >

                        <VStack>

                            <Text mt={2} fontWeight={'semibold'}>Admit One</Text>
                            <QRCode
                                size={100}
                                value={qrData} />

                            <HStack>
                               
                                <Text fontSize='sm'>
                                    Email: {details[0].ownerEmail}
                                </Text>
                            </HStack>

                            <Image src={bg} objectFit={'fill'} />
                        </VStack>

                    </Flex>

                </Box> */}
          
            </Stack>  
            </Center>

        </>
    )
}

export default ReceiptTicket