
// import './App.css';
import {
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Badge,
  InputGroup,
  InputLeftElement,
  Input,
  FormControl,
  Spinner,
  Button,
  Text,
  Alert,
  AlertIcon,
  AlertDescription
} from '@chakra-ui/react'

import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Link,
} from "react-router-dom";

import React,{ useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import BuyZWL from './components/airtime/BuyZWL';
import AirtimeConfig from './components/airtime/airtmeConfig';
import Success from './components/airtime/Success';
import BuyZESA from './components/airtime/BuyZESA';
import BuyUSD from './components/airtime/BuyUSD';
import ZesaSuccess from './components/airtime/ZesaSuccess';
import Landing from './components/pages/Landing';
import Land from './components/pages/Land';
import ZesaConfig from './components/zesa/ZesaConfig';
import RequestToken from './components/airtime/RequestToken';
import BuyTicket from './components/airtime/BuyTicket';
import TicketsConfig from './components/airtime/TicketsConfig';
import CultureFest from './components/airtime/CultureFest';
import Receipt from './components/airtime/Receipt';
import AlbunReceipt from './components/airtime/AlbunReceipt'
import Tickets from './components/tickets/Tickets';
import ReceiptTicket from './components/tickets/ReceiptTicket';
import SystemUpgrade from './components/pages/SystemUpgrade';


function App() {
  
  return (
    <div className="App">



      {/* <Dashboard /> */}
      <BrowserRouter>   
      <Routes>
        {/* <Route path='/verify-email' element={<} */}
        <Route path='/airtime-purchase-zwl' element={<BuyZWL />} />
        <Route path='/airtime-purchase-usd' element={<BuyUSD />} />
        <Route path='/buy-zesa' element={<BuyZESA />} />
        <Route path='/request-token' element={<RequestToken/>} />
        <Route path = '/airtime' element = {<AirtimeConfig />} />
        {/* <Route path = '/land' element = {<Landing />} /> */}
        {/* <Route path = '/' element = {<Land />} /> */}
        <Route path='/' element={<SystemUpgrade />} />
        <Route path = '/airtime-purchase-success' element = {<Success />} />
        <Route path ='/zesa-purchase-sucess' element = {<ZesaSuccess/>}/>
        <Route path='/zesa-payment' element={<ZesaConfig />} />
        <Route path='/tickets' element={<TicketsConfig/>} />
        <Route path='/buy-ticket' element={<BuyTicket/>} />
        <Route path='/culture-fest' element={<CultureFest/>} />
        <Route path='/receipt' element={<Receipt/>} />
        <Route path='/albun-receipt' element={<AlbunReceipt/>}/>
        <Route path='/buy-online-tickets' element={<Tickets />} />
        <Route path='/receipt-ticket-success' element={<ReceiptTicket />} />
       
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
