import React, { useEffect, useState } from 'react'
import NavBar from '../pages/Navbar'
import { Box, Flex, Heading, Image, Stack, Text, IconButton, useBreakpointValue, Card, CardBody, Divider, CardFooter, ButtonGroup, Button, HStack, Center, SimpleGrid } from '@chakra-ui/react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import tic from '../images/tikets.png'
import culture from '../images/culture2.jpg'
import jt from '../images/voltsjtculture.jpg'
import run from '../images/albun.jpeg'
import Slider from 'react-slick'
import { MdShoppingBasket, MdShoppingCart } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import axios from '../../api/axios'
// const PAYMENT_URL = 'https://shop.onemoney.co.zw/eventus/transaction/pay'
const EVENTS = 'eventus/events/all?status=OPEN'

const Carousel = () => {
    const settings = {
        dots: true,
        arrows: false,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        slidesToShow: 1,
    };

    const [slider, setSlider] = useState(null)

    // These are the breakpoints which changes the position of the
    // buttons as the screen size changes
    const top = useBreakpointValue({ base: '90%', md: '50%' })
    const side = useBreakpointValue({ base: '30%', md: '10px' })

    // These are the images used in the slide
    const cards = [
        `${run}`,
        `${culture}`,
        `${jt}`
    ]


    return (
        <Box position={'relative'} height={'800px'} width={'400px'} overflow={'hidden'}>
            {/* CSS files for react-slick */}
            <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />
            {/* Left Icon */}
            <IconButton
                aria-label="left-arrow"
                colorScheme="messenger"
                borderRadius="full"
                position="absolute"
                left={side}
                top={top}
                transform={'translate(0%, -50%)'}
                zIndex={2}
                onClick={() => slider?.slickPrev()}>
                <BiLeftArrowAlt />
            </IconButton>
            {/* Right Icon */}
            <IconButton
                aria-label="right-arrow"
                colorScheme="messenger"
                borderRadius="full"
                position="absolute"
                right={side}
                top={top}
                transform={'translate(0%, -50%)'}
                zIndex={2}
                onClick={() => slider?.slickNext()}>
                <BiRightArrowAlt />
            </IconButton>
            {/* Slider */}
            <Slider {...settings} ref={(slider) => setSlider(slider)}>
                {cards.map((url, index) => (
                    // <Box
                    //   key={index}
                    //   height={'6xl'}
                    //   position="relative"
                    //   backgroundPosition="center"
                    //   backgroundRepeat="no-repeat"
                    //   backgroundSize="cover"
                    //   boxSize={'1900px'}
                    //   backgroundImage={`url(${url})`}
                    // />
                    <Image
                        boxSize={'600px'}
                        objectFit={'cover'}
                        src={`${url}`}
                        padding={2}
                        height={'400px'}
                    />
                ))}
            </Slider>
        </Box>
    );
};

function StatsCard(props) {
   
    const { pic,title,location, time, amount, buton } = props;
    return (
        <Card maxW='sm'>
        <CardBody>
           {pic}
            <Stack mt='2' align={'center'} spacing='3'>
                <Heading size='md' color={'#FF7300'} >{title}</Heading>
                <Text fontSize={'md'} fontWeight={'semibold'}>
                    {location}
                </Text>
                <Text fontSize='sm'>
                   {time}
                </Text>
                <Text color={'#FF7300'} fontStyle={'oblique'} fontWeight={'semibold'} fontSize={'lg'}>
                  USD{amount}
                </Text>
            </Stack>
            <Center m={4}>
                {buton}
                {/* <Button onClick={()=>navigate('/culture-fest')} width={'300px'} leftIcon={<MdShoppingCart />} colorScheme='orange' variant='outline'>
                    Buy A Ticket
                </Button> */}
            </Center>
        </CardBody>
    </Card>
    );
  }
const Tickets = () => {
    const navigate = useNavigate();
    const [eventId, setEvent] = useState('671b9376bc7b0d6e0ad31ddc')
    const [eventData, setEventData]= useState([])
    const [loading, setLoading]= useState(false)

    const getDetails = async () => {
        try {
         
          setLoading(true)
          const response = await axios.get(`${EVENTS}`, {
    
          })
          if (response.data.success === true) {
            setEventData(response.data.body)
            console.log(response.data.body)
            setLoading(false)
          }
        } catch (error) {
        //   console.log(error);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // toast({
            //   title: "Error",
            //   description: `Server responded with ${error.message}`,
            //   status: "error",
            //   duration: 5000,
            //   isClosable: true,
            // });
          } else {
            // Something happened in setting up the request that triggered an Error
    
    
          }
        }
      }
    
      useEffect(() => {
        getDetails();
        // setTimeout(() => {
    
        // }, 6000);
    
      }, [])

      function formatDate(dateString) {
        // Parse the input date string
        const date = new Date(dateString);
      
        // Format the date as desired (e.g., "November 23, 2024")
        return new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(date);
      }
    return (
        <>
            <NavBar />
            <Stack
                mt={-3}
                textAlign={'center'}
                align={'center'}
                spacing={{ base: 4, md: 8 }}
                py={{ base: 4, md: 6 }}
            >
                <Flex align='center' justify='space-between' p={5}>

                    {/* Left Image */}
                    <Image
                        boxSize="100px"
                        objectFit="cover"
                        src={tic}
                        alt="Left Image"
                    />
                    <Box ml={5} mr={4}>


                        <Heading
                            mb={4}
                            fontWeight={600}
                            fontSize={{ base: 'xl', sm: '2xl', md: '6xl' }}
                            lineHeight={'110%'}>
                            <Text as={'span'} color={'orange.400'}>
                              OneMoney
                            </Text>
                           {''} Online Tickets
                        </Heading>
                        <Text color={'gray.500'} maxW={'2xl'} >
                            Get your tickets in advance with ease using OneMoney! Whether you're booking for an upcoming event or show, you can now pay securely through OneMoney. Enjoy a seamless and fast transaction experience.
                        </Text>
                    </Box>
                    {/* Right Image */}
                    <Image
                        boxSize="100px"
                        objectFit="cover"
                        src={tic}
                        alt="Right Image"
                    />
                </Flex>
            </Stack>
                <Flex align={'center'} justify={'center'} flex={3}>
                    <SimpleGrid mb={2} columns={{ base: 1, md: 2, sm: 2 }} spacing={{ base: 2, md: 3, lg: 4 }} >

                        {/* <Card maxW='sm'>
                            <CardBody>
                                <Image
                                    src={culture}
                                    alt='Green double couch with wooden legs'
                                    borderRadius='lg'
                                />
                                <Stack mt='2' align={'center'} spacing='3'>
                                    <Heading size='md' color={'#FF7300'} >263 CultureFest</Heading>
                                    <Text fontSize={'md'} fontWeight={'semibold'}>
                                        Belgravia Sports club
                                    </Text>
                                    <Text fontSize='sm'>
                                        Saturday 26 February 2024 10:00am
                                    </Text>
                                    <Text color={'#FF7300'} fontStyle={'oblique'} fontWeight={'semibold'} fontSize={'lg'}>
                                       From US$20
                                    </Text>
                                </Stack>
                                <Center m={4}>

                                    <Button onClick={()=>navigate('/culture-fest')} width={'300px'} leftIcon={<MdShoppingCart />} colorScheme='orange' variant='outline'>
                                        Buy A Ticket
                                    </Button>
                                </Center>
                            </CardBody>
                        </Card> */}
                
                         {eventData?.map((data,index)=> {
                            console.log(eventData[0]);
                            return(

                         <StatsCard
                            
                         
                        pic= {

                            <Image
                            src={run}
                            alt='culture'
                            borderRadius='lg'
                        />
                        }
                        key={index}
                        title={data.name}
                        location={data.location}
                        time={formatDate(data.date)}
                        amount={data.prices[0].amount}
                        buton={<Button onClick={()=>navigate('/buy-ticket',{
                            state: eventData})} 
                            _hover={{
                                bg: '#FF7300',
                                color:'black'
                              }}
                            width={'300px'} leftIcon={<MdShoppingCart />} colorScheme='orange' variant='outline'>
                        Buy A Ticket
                    </Button> }
                        />  
                            )
                        })}
                        {/* <Card maxW='sm'>
                            <CardBody>
                                <Image
                                    src={run}
                                    alt='Green double couch with wooden legs'
                                    borderRadius='lg'
                                />
                                <Stack mt='2' align={'center'} spacing='3'>
                                    <Heading size='md'color={'#FF7300'} >Honde Valley Marathon</Heading>
                                    <Text fontSize={'md'} fontWeight={'semibold'}>
                                        Belgravia Sports club
                                    </Text>
                                    <Text fontSize='sm'>
                                        Saturday 26 November 2024 10:00am
                                    </Text>
                                    <Text color={'#FF7300'} fontStyle={'oblique'} fontWeight={'semibold'} fontSize={'lg'}>
                                        US$20
                                    </Text>
                                </Stack>
                                <Center m={4}>

                                    <Button onClick={()=>navigate('/buy-ticket')} width={'300px'} leftIcon={<MdShoppingCart />} colorScheme='orange' variant='outline'>
                                        Buy A Ticket
                                    </Button>
                                </Center>
                            </CardBody>

                        </Card> */}
                    </SimpleGrid>


                   
                </Flex>
               


           

        </>
    )
}

export default Tickets