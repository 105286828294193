'use client'

import { Box, Heading, Icon, Image, Stack, Text } from '@chakra-ui/react'
import main from '../images/seen.jpg'
import Navbar from '../pages/Navbar'
import { CiWarning } from "react-icons/ci";


export default function SystemUpgrade() {
  return (
    <>
   <Navbar />
    <Box textAlign="center" py={6} px={6}>
        <Icon as={CiWarning} boxSize={20}  color='orange.400' />
      <Heading as="h2" size="xl" textColor={'#FF7300'} mt={4} mb={2}>
        Website Maintenance
      </Heading>
      <Stack justify={'center'} align={'center'} >
    <Image
        src={main}
        boxSize={400}
        objectFit={'cover'}
        />

    </Stack>
      <Text fontSize={{ base: '1xl', sm: 'lg', md: 'xl'}} color={'gray.500'} fontWeight={'semibold'}>
      "Dear Customers, our website is currently undergoing maintenance to improve your experience.
       During this time, some features may be temporarily unavailable.
       We appreciate your patience and apologize for any inconvenience. Please check back soon!"
      </Text>
    </Box>
    
    </>
  )
}