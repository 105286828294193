import React from 'react'

import { Tabs,
     TabList, 
     TabPanels, 
     Tab, 
     TabPanel, 
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Flex,
    chakra,
    Image

    } from '@chakra-ui/react'
    import { BsChevronRight, BsPerson } from 'react-icons/bs';
    import { NavLink, useNavigate } from 'react-router-dom'
    import Navbar from  '../pages/Navbar'
    import BuyTicket from './BuyTicket';
    import CultureFest from './CultureFest';

function TicketsConfig() {

  return (
   <>
    <Navbar />
    <Box bg={'white'}  pt={1} px={{ base: 2, sm: 8, md: 10 }}>


    <Tabs isLazy>
    <TabList  mb='1em'>
      <Tab> Albun Marathon Ticket</Tab>
        <Tab> Culture Fest Ticket </Tab>
    </TabList>
    <TabPanels>
      {/* initially mounted */}
     
      <TabPanel>
       <BuyTicket/>
      </TabPanel>
      <TabPanel>
        <CultureFest/>
      </TabPanel>
    </TabPanels>
  </Tabs>
        </Box>
   </>
  )
}

export default TicketsConfig
